import React, { useRef, useState } from "react";
import "./App.css";
import backgroundImage from "./img/A.jpeg";

const Home = () => {
  const scrollContainerRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement && scrollContainerRef.current) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      id="home"
      ref={scrollContainerRef}
      className="w-screen h-screen flex-shrink-0 snap-start relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center px-4">
        <div className="text-center text-white max-w-4xl">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            Driscoll Co. Real Estate
          </h1>
          <h2 className="text-3xl md:text-4xl mb-8">
            St. Louis and Surrounding Missouri Counties
          </h2>
          <p className="text-xl md:text-2xl mb-8">
            Expert Real Estate Services Since 2004
          </p>
        </div>
      </div>

      {/* White Bottom Section for Buttons */}
      <div className="absolute bottom-0 w-full bg-white py-4 flex justify-center items-center px-4">
        <div className="flex flex-wrap justify-center gap-4 md:gap-8">
          <button
            onClick={() => scrollToSection("commercial")}
            className="text-lg font-semibold text-gray-800 hover:text-red-600 transition duration-300 rounded-md px-8 py-3"
          >
            Explore
          </button>
          <button
            onClick={() => scrollToSection("contact")}
            className="text-lg font-semibold text-gray-800 hover:text-red-600 transition duration-300 rounded-md px-8 py-3"
          >
            Contact
          </button>

          {/* Combined View & Download Button */}
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              className="text-lg font-semibold text-gray-800 hover:text-red-600 transition duration-300 flex items-center rounded-md px-8 py-3"
            >
              Application
              <span className="ml-2 text-xs">▼</span>
            </button>

            {/* Dropdown Options */}
            {isDropdownOpen && (
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-40 bg-white shadow-lg rounded-md py-1 text-gray-800 z-50">
                <button
                  onClick={() => {
                    setModalOpen(true);
                    setDropdownOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                >
                  View Application
                </button>
                <a
                  href="/documents/Rental_Application.pdf"
                  download
                  className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                >
                  Download PDF
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for Viewing Application */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-11/12 md:w-3/4 lg:w-1/2 relative">
            <h2 className="text-2xl font-bold mb-4 text-gray-900">
              Rental Application
            </h2>
            <iframe
              src="/documents/Rental_Application.pdf"
              className="w-full h-[500px] md:h-[600px] border rounded-lg"
              title="Rental Application"
            ></iframe>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setModalOpen(false)}
                className="bg-red-600 text-white px-4 py-2 rounded-md text-sm font-semibold 
                     hover:bg-red-700 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Home;
